@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Polaris-UserMenu-UserMenu__Detail , .Polaris-UserMenu-UserMenu__Name {
  margin: 0 0 0 0;
}



/* BASIC */

/*html {*/
/*background-color: #56baed;*/
/*}*/

/*body {*/
/*font-family: "Poppins", sans-serif;*/
/*height: 100vh;*/
/*}*/

.wrapper-auth a {
    color: #56baed;
    display:inline-block;
    text-decoration: none;
    font-weight: 400;
}

.wrapper-auth a:hover {
    color: #002bcc;
}

.wrapper-auth h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display:inline-block;
    margin: 40px 8px 10px 8px;
    color: #cccccc;
}



/* STRUCTURE */

.wrapper-auth {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
}

.wrapper-auth #formContent-auth {
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
    text-align: center;
}

.wrapper-auth #formFooter-auth {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    border-radius: 0 0 10px 10px;
}



/* TABS */

.wrapper-auth h2.inactive {
    color: #cccccc;
}

.wrapper-auth h2.active {
    color: #0d0d0d;
    border-bottom: 2px solid #5fbae9;
}



/* FORM TYPOGRAPHY*/

.wrapper-auth input[type=button], input[type=submit], input[type=reset]  {
    background-color: #56baed;
    border: none;
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    transition: all 0.3s ease-in-out;
}

.wrapper-auth input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
    background-color: #39ace7;
}

.wrapper-auth input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
}

.wrapper-auth input[type=text], input[type=password] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 85%;
    border: 2px solid #f6f6f6;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
}

.wrapper-auth input[type=text]:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
}

.wrapper-auth input[type=text]:placeholder {
    color: #cccccc;
}



/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.wrapper-auth .fadeInDown-auth {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown-auth {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown-auth {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn-auth { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn-auth { from { opacity:0; } to { opacity:1; } }

.fadeIn-auth {
    opacity:0;
    -webkit-animation:fadeIn-auth ease-in 1;
    animation:fadeIn-auth ease-in 1;

    -webkit-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:1s;
    animation-duration:1s;
}

.fadeIn-auth.first {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.fadeIn-auth.second {
    height: 50px;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.fadeIn-auth.third {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.fadeIn-auth.fourth {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover-auth{
    font-size: 15px;
}


.underlineHover-auth:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #56baed;
    content: "";
    transition: width 0.2s;
}

.underlineHover-auth:hover {
    color: #4285dd !important;
}

.underlineHover-auth:hover:after{
    width: 100%;
}



/* OTHERS */

*:focus {
    outline: none;
}

#icon-auth {
    width:60%;
}
/*
    DEMO STYLE
*/
body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

.wrapper-panel p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    /*color: #999;*/
}

.wrapper-panel a,
a:hover,
a:focus {
    color: #fff;
    text-decoration: none!important;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    /*background: #fff;*/
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper-panel {
    display: flex;
    width: 100%;
}

#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    background: #56baed;
    color: #fff !important;
    transition: all 0.3s;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #39ace7;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #39ace7;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    /*color: #39ace7;*/
    background: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #39ace7;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #39ace7;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

.download {
    background: #fff;
    color: #39ace7!important;
}

.download:hover {
    color: #000000 !important;
}

a.article {
    background: #39ace7;
}
a.article:hover {
    background: #4285dd !important;
    color: #fff ;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: calc(100% - 250px);
    padding: 40px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

#content.active {
    width: 100%;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #content {
        width: 100%;
    }
    #content.active {
        width: calc(100% - 250px);
    }
    #sidebarCollapse span {
        display: none;
    }
}

.Polaris-TextField__Input {
    padding: 5px 10px !important;
    margin: 0 !important;
}


.content{
    font-size: 130%;
}
.select1{
    width:  12% !important;
    float: right;
    /* margin-right:18%; */
}
.Polaris-Pagination{
    float: left;
    width:  auto !important;
    /* margin-left: 60%; */
}
.data_table{
    margin-top: 20px;
}


.Toggle{
    margin-top: 60px;
}
.userDetails{
    font-size: 150%;
    font-weight: 500;
}
.userHeading{
    font-size: 150%;
    font-weight: 900;
}
.loading{
    height:100px; 
    width:150px;
    /* margin-left: 50%;
    transform: translateX(-20%); */
}
.variant{
    width: 48%;
    float: left;
}
.orders{
    width: 48%;
    float: right;
}
